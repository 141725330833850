<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Lista jednostek</h3>
        </header>
        <Button type="button" label="Dodaj jednostkę" v-on:click="click"/>
        <DataTable :value="content" v-model:filters="filters" :loading="loading" removableSort responsiveLayout="scroll"
                   :globalFilterFields="['name', 'place']">
            <template #header>
                <div class="p-d-flex p-jc-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" placeholder="Wyszukaj"/>
                        </span>
                </div>
            </template>
            <template #empty>
                Nie znalieziono jednostek
            </template>
            <template #loading>
                Ładwanie jednostek. Proszę czekać
            </template>
            <Column field="name" header="Nazwa" :sortable="true"></Column>
            <Column field="place" header="Miejscowość" :sortable="true"></Column>
            <Column field="type.key" header="Typ" :sortable="true"></Column>
            <Column headerStyle="width: 12em" bodyStyle="text-align: center; display: inline-block;">
                <template #body="slotProps">
                    <Button type="button" label="Edytuj"
                            v-on:click="editUnit(slotProps.data.id)"></Button>
                    <Button type="button" class="p-button-danger" label="Usuń"
                            v-on:click="removeUnit(slotProps.data.id)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import UnitService from "../../services/unit.service";
    import {FilterMatchMode} from 'primevue/api';

    export default {
        inject: ['global'],
        name: "UnitsList",
        data() {
            return {
                content: null,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
                },
                loading: true,
            };
        },
        methods: {
            click: function (event) {
                this.$router.push({path: `${this.global.state.instancePatch}/jednostki/dodaj`});
                if (event) {
                    console.log(event.target);
                }
            },
            editUnit: function (id) {
                this.$router.push({path: `${this.global.state.instancePatch}/jednostki/edytuj/${id}`});
            },
            removeUnit: function (id) {
                UnitService.removeUnit(id).then(
                    () => {
                        this.getList();
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Usunięto',
                            detail: 'Poprawnie usunięto jednostkę',
                            life: 3000
                        });
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            getList: function () {
                UnitService.getAllUnits().then(
                    (response) => {
                        this.loading = false;
                        this.content = response.data.items;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
        },
        mounted() {
            console.log(this.global.state.instancePatch);
            this.getList();
        },
    };
</script>
